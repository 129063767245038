import React from "react";

const Tooltip = ({
    children,
    title,
    placement = "top",
    className = "whitespace-nowrap px-2 py-1",
}) => {
    if (placement === "right") {
        return (
            <div className="relative">
                <div className="peer">{children}</div>
                <div
                    className={`z-10 absolute left-1/2 transform -translate-x 
                                 invisible peer-hover:visible
                                 text-sm text-gray-100 bg-gray-700 
                                 backdrop-blur rounded ${className}`}
                >
                    {title}
                </div>
            </div>
        );
    }

    return (
        <div className="relative">
            <div className="peer">{children}</div>
            <div
                className={`z-50 absolute ${
                    placement === "top" ? "-top-8" : "-bottom-8"
                } left-1/2 transform -translate-x-1/2 
                             invisible peer-hover:visible
                             text-sm text-gray-100 bg-gray-900/70 
                             backdrop-blur rounded ${className}`}
            >
                {title}
            </div>
            <div
                style={{
                    position: "absolute",
                    width: 0,
                    height: 0,
                    borderLeft: "5px solid transparent",
                    borderRight: "5px solid transparent",
                    borderTop: placement === "top" ? "6px solid #555" : "",
                    borderBottom: placement === "bottom" ? "6px solid #555" : "",
                    marginTop: placement === "top" ? -8 : 34,
                }}
                className="absolute top-1 left-1/2 transform -translate-x-1/2 invisible peer-hover:visible"
            ></div>
        </div>
    );
};

export default Tooltip;
