import React, { useState } from "react";

import { Dialog, Popover } from "@headlessui/react";
import { ShareIcon, LinkIcon, MailIcon, ClipboardCopyIcon } from "@heroicons/react/outline";
import { prospectHost } from "@/utils/config";
import Tooltip from "@/ui/Tooltip";
import { IconButton } from "@/ui/IconButton";
import Button from "@/ui/Button";

import { RiShareForwardLine } from "react-icons/ri";

const ShareButton = ({ community, dark }) => {
    const [open, setOpen] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(prospectHost + "community/" + community.nameId);
    };

    return (
        <div>
            <button
                className={`${
                    dark
                        ? "text-gray-50 hover:text-primary-500 hover:bg-gray-200"
                        : "text-gray-500 hover:text-primary-50 hover:bg-primary-500"
                }
                        focus:ring-2 rounded-full text-center
                        px-1 py-1 font-semibold `}
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                }}
            >
                <RiShareForwardLine className="w-6 h-6" />
            </button>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                className="fixed z-40 top-16 bottom-16 left-4 right-4 sm:top-32 sm:bottom-6 sm:left-16 sm:right-16 overflow-y-auto"
            >
                <div className="flex items-center justify-center">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />

                    <div className="z-50 p-4 sm:px-16 sm:py-8 bg-white w-full border rounded-xl">
                        <div className="relative bg-gray-50">
                            <h3 className="text-lg text-primary-500 font-bold">
                                Share Community Link
                            </h3>

                            <div className="mt-4 flex items-center space-x-4">
                                <LinkIcon className="w-5 h-5 text-primary-500" />

                                <div className="w-[200px] sm:w-full text-sm sm:text-base">
                                    {prospectHost + "community/" + community.nameId}
                                </div>

                                {/*<Tooltip title="Copy">
                                    <IconButton className="" onClick={handleCopy}>
                                        <ClipboardCopyIcon className="w-5 h-5 text-primary-500" />
                                    </IconButton>
                                </Tooltip>*/}
                            </div>

                            <div className="mt-2 flex items-center">
                                <MailIcon className="w-5 h-5 mr-4 text-primary-500" />
                                <a
                                    href={`mailto:?subject=${
                                        community.name
                                    }&body=Community%20Link%0A%0C${
                                        prospectHost + "community/" + community.nameId
                                    }`}
                                    className="text-primary-500 hover:underline"
                                >
                                    Email Community Link
                                </a>
                            </div>
                        </div>

                        <div className="mt-8 flex justify-between">
                            <Button variant="outlined" onClick={handleCopy}>
                                Copy to Clipboard
                            </Button>
                            <Button onClick={() => setOpen(false)}>Close</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );

    /*return (
        <Popover className="relative">
            {({ close }) => (
                <>
                    <Popover.Button
                        className={`focus:ring-2 rounded-full text-center
                        text-gray-800
                        px-1 py-1 font-semibold hover:text-primary-50 hover:bg-primary-500`}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <ShareIcon className="w-6 h-6" />
                    </Popover.Button>

                    <Popover.Panel className="absolute -right-4 z-20 w-[350px] sm:w-[450px] xl:w-[500px]  px-4 mt-2">
                        <div className="overflow-hidden rounded shadow-2xl ring-1 ring-black ring-opacity-5">
                            <div className="relative bg-gray-50 px-4 pt-4 pb-6 rounded border-2 border-primary-500">
                                <h3 className="text-lg text-primary-500 font-bold">
                                    Share Community Link
                                </h3>

                                <div className="mt-4 flex items-center">
                                    <LinkIcon className="w-5 h-5 mr-2" />
                                    {prospectHost + community.nameId}

                                    <Tooltip title="Copy">
                                        <IconButton className="ml-4" onClick={handleCopy}>
                                            <ClipboardCopyIcon className="w-5 h-5" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="mt-2 flex items-center">
                                    <MailIcon className="w-5 h-5 mr-2" />
                                    <a
                                        href={`mailto:?subject=${
                                            community.name
                                        }&body=Community%20Link%0A%0C${
                                            prospectHost + community.nameId
                                        }`}
                                        className="text-primary-500 hover:underline"
                                    >
                                        Email Community Link
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </>
            )}
        </Popover>
    );*/
};

export default ShareButton;
