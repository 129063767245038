import React, { useCallback, useContext, useRef } from "react";
import { useEventListener } from "@/utils/hooks";

const DialogContext = React.createContext();

const Dialog = ({ open, onClose, className, children }) => {
    const ref = useRef();

    // Handle `Escape` to close
    useEventListener(ref.current?.defaultView, "keydown", (event) => {
        if (event.key !== "Escape") return;
        //if (dialogState !== DialogStates.Open) return;
        //if (hasNestedDialogs) return;
        event.preventDefault();
        event.stopPropagation();
        onClose();
    });

    if (open) {
        return (
            <DialogContext.Provider value={{ onClose }}>
                <div ref={ref} className={className} role="dialog" aria-modal={true}>
                    {children}
                </div>
            </DialogContext.Provider>
        );
    } else {
        return null;
    }
};

const Overlay = ({ className }) => {
    const c = useContext(DialogContext);
    const onClose = c?.onClose;

    const handleClick = useCallback(
        (event) => {
            if (event.target !== event.currentTarget) return;
            //if (isDisabledReactIssue7711(event.currentTarget)) return event.preventDefault()
            event.preventDefault();
            event.stopPropagation();
            if (onClose) {
                onClose();
            }
        },
        [onClose]
    );

    return <div onClick={handleClick} aria-hidden={true} className={className}></div>;
};

const Title = ({ children, className }) => {
    return <div className={className}>{children}</div>;
};

const Description = ({ children, className }) => {
    return <div className={className}>{children}</div>;
};

Dialog.Overlay = Overlay;
Dialog.Title = Title;
Dialog.Description = Description;

export default Dialog;
