class Memory {
    items = {};

    setItem(key, value) {
        this.items[key] = value;
    }

    getItem(key) {
        return this.items[key];
    }

    getAll() {
        return this.items;
    }
}

const MemorySingleton = (function () {
    var instance;

    function createInstance() {
        var object = new Memory();
        return object;
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        },
    };
})();

export default MemorySingleton.getInstance();
