import React from "react";

const Tooltip = ({ children, title }) => {
    return (
        <div className="relative">
            <div className="peer">{children}</div>
            <div
                className={`z-50 absolute -top-8 left-1/2 transform -translate-x-1/2 
                             invisible peer-hover:visible
                             text-sm text-gray-100 bg-gray-900/70 backdrop-blur rounded-md px-2 py-1 whitespace-nowrap`}
            >
                {title}
            </div>
        </div>
    );
};

export default Tooltip;
